<template>
  <v-row no-gutters class="client__container bg-primary-variant">
    <v-container ref="client_cards">
      <v-row
        no-gutters
        justify="center"
        class="animatedcards"
        :class="{'animatedcards--show': show_cards, 'animatedcards--hide': !show_cards}"
      >
        <p class="client__subtitle">{{ $t('our_clients.title') }}</p>
      </v-row>
      <v-row
        no-gutters
        justify="center"
        class="animatedcards mt-2"
        :class="{'animatedcards--show': show_cards, 'animatedcards--hide': !show_cards}"
      >
        <p class="client__title">{{ $t('our_clients.content') }}</p>
      </v-row>
      <v-row
        no-gutters
        justify="center"
        class="animatedcards"
        :class="{'animatedcards--show': show_cards, 'animatedcards--hide': !show_cards}"
      >
        <div class="slick-container">
          <slick ref="slick_clients" :options="slickOptions">
            <div
              v-for="(client, index) in clients"
              :key="`client_${index}`"
              class="d-inline-flex justify-center align-center"
              style="height: 120px !important; padding: 20px !important;"
            >
              <v-img
                v-if="client.pic"
                :src="require(`@/assets/img/companies/${client.pic}`)"
                class="client__icon"
                contain
              />
            </div>
          </slick>
        </div>
      </v-row>
      <v-row
        no-gutters
        justify="center"
        style="pointer-events: none;"
        class="animatedcards"
        :class="{'animatedcards--show': show_cards, 'animatedcards--hide': !show_cards}"
      >
        <div class="client__divider"></div>
      </v-row>
      <v-row
        no-gutters
        justify="center"
        class="review-container animatedcards"
        :class="{'animatedcards--show': show_cards, 'animatedcards--hide': !show_cards}"
      >
        <v-carousel
          continuous
          hide-delimiters
          height="380"
          hide-delimiter-background
          v-model="clientReviewIndex"
          ref="carousel"
        >
          <v-carousel-item
            v-for="(client, index) in clients_carrousel"
            :key="`client_carrousel_${index}`"
          >
            <v-row no-gutters justify="center" align="center">
              <div class="review">
                <p class="review-text">{{client.review.text}}</p>
              </div>
            </v-row>
            <v-row no-gutters justify="center" align="center" class="mt-12">
              <div>
                <v-img
                  v-if="client.pic"
                  :src="require(`@/assets/img/companies/${client.pic}`)"
                  class="client__img"
                  contain
                />
              </div>
            </v-row>
            <v-row no-gutters justify="center" align="center" class="mt-6">
              <p class="reviewer-author">{{ client.review.author }}</p>
            </v-row>
            <v-row no-gutters justify="center" align="center">
              <p class="reviewer-position">{{ client.review.position }}</p>
            </v-row>
          </v-carousel-item>
        </v-carousel>
      </v-row>
    </v-container>
  </v-row>
</template>

<script>
import Slick from 'vue-slick';
import '../../node_modules/slick-carousel/slick/slick.css';
import '../../node_modules/slick-carousel/slick/slick-theme.css';

export default {
  components: {
    Slick,
  },
  data() {
    return {
      clients_carrousel: [
        {
          pic: 'mandomedio.png',
          name: 'MandoMedio',
          review: {
            text: 'En desarrollo de software es difícil dar con lo que el cliente necesita, es al final siempre co-creación. Estimo que Djavu con Mandomedio entendió eso y lo reflejó en lo más particular, dígase diálogo, entregables, cobros, testing, etc.. para lograr un muy buen resultado.',
            author: 'Andrés Gomez',
            position: 'Director',
          },
        },
        {
          pic: 'manungo.png',
          name: 'Mañungo',
          review: {
            text: 'Son jóvenes con amplios conocimientos técnicos, confiables, veloces y motivados, capaces de enfrentar y resolver desafíos complejos.',
            author: 'Manuel José Ovalle',
            position: 'Director',
          },
        },
        {
          pic: 'thedogcompany.png',
          name: 'The Dog Company',
          review: {
            text: 'Trabajar con Djavu es un agrado, además de una demostrable capacidad técnica siempre están disponibles para involucrarse más allá de sus responsabilidades directas.',
            author: 'Luis Fuentes Larenas',
            position: 'CTO',
          },
        },
        {
          pic: 'nalkip.svg',
          name: 'Nalkip',
          review: {
            text: 'Djavu nos permitió contratar HH de calidad y en una situación de emergencia para algo muy puntual. Contratar a alguien para eso no era posible. Con Djavu tengo la calidad que yo contrato normalmente pero la tengo on-demand.',
            author: 'Juan Luis Larroucau',
            position: 'CEO',
          },
        },
      ],
      clients: [{
        pic: 'asicom.png',
        name: 'Asicom',
      },
      {
        pic: 'consiliumbots.png',
        name: 'Consilium Bots',
      },
      {
        pic: 'southernrock_alpha.png',
        name: 'SouthernRock Geophysics',
      },
      {
        pic: 'Vixonic.png',
        name: 'Vixonic',
      },
      {
        pic: 'mandomedio.png',
        name: 'MandoMedio',
      },
      {
        pic: 'manungo.png',
        name: 'Mañungo',
      },
      {
        pic: 'thedogcompany.png',
        name: 'The Dog Company',
      },
      {
        pic: 'nalkip.svg',
        name: 'Nalkip',
      }],
      clientReviewIndex: null,
      show_cards: false,
      slickOptions: {
        dots: true,
        arrows: false,
        slidesToShow: 5,
        slidesToScroll: 2,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
            },
          },
        ],
      },
    };
  },
  mounted() {
    window.addEventListener('scroll', this.listener);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.listener);
  },
  beforeUpdate() {
    if (this.$refs.slick_clients) {
      this.$refs.slick_clients.destroy();
    }
  },
  updated() {
    // eslint-disable-next-line func-names
    this.$nextTick(function () {
      if (this.$refs.slick_clients) {
        this.$refs.slick_clients.create(this.slickOptions);
      }
    });
  },
  methods: {
    listener() {
      const vm = this;
      this.$nextTick().then(() => {
        const cardsdistance = vm.$refs.client_cards.getBoundingClientRect().top - window.innerHeight;
        const cardsVisible = cardsdistance < 0;
        if (cardsVisible !== vm.show_cards) {
          vm.show_cards = cardsVisible;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.client-review-text.position{
  font-size: 90%;
}
.client-icon {
  -webkit-filter: grayscale(90%); /* Safari 6.0 - 9.0 */
  filter: grayscale(90%);
  opacity: 0.4;
  transition: transform 0.25s ease-out, opacity 0.25s ease-out;
}
.client-icon-active {
  transform: scale(1.2) translateY(-20px);
  -webkit-filter: grayscale(0%);
  filter: none;
  opacity: 1;
}
.client-icon:hover {
  transform: scale(1.08);
}
.client-review-text.author{
  color: darkblue;
}
.slick-container {
  margin-top: 100px;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    margin-top: 52px;
  }
  max-width: 1022px;
  width: 100%;
  max-height: 200px;
}
.client__container {
  padding-top: 120px;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding-top: 88px;
    padding-bottom: 88px;
  }
}
.client__title {
  font-size: 2.5rem;
  font-weight: 900;
  color: $djablue;
  text-align: center;
}
.client__subtitle {
  font-weight: 600;
  color: $djablue;
  font-size: 1rem;
  text-align: center;
}
.client__icon {
  width: 100px !important;
  height: 100px !important;
  margin: 0 10px;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  transition: all .3s ease;
  filter: grayscale(80%);
  opacity: 0.8;

  &:hover {
    width: 120px !important;
    height: 120px !important;
    margin: 0;
    filter: grayscale(0%);
    opacity: 1;
  }
}
.client__divider {
  max-width: 550px;
  height: 1px;
  background-color: $djablue;
  width: 100%;
  margin-top: 90px;
  opacity: 0.4;
  z-index: 0;
}
.review-container {
  margin-top: 72px;
}
.review {
  max-width: 750px;
}
.review-text {
  font-weight: 500;
  color: $djablue;
  font-size: 1.5rem;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    font-size: 1rem;
  }
  @media #{map-get($display-breakpoints, 'sm-only')} {
    font-size: 1.2rem;
  }
  text-align: center;
}
.client__img {
  width: 120px !important;
  margin: 0 10px;
}
.reviewer-author {
  color: $djablue;
  font-size: 1rem;
  font-weight: 700;
}
.reviewer-position {
  color: $djablue;
  font-size: 1rem;
  font-weight: 400;
}
</style>
