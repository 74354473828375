<template>
  <v-row no-gutters class="work__container">
    <v-container ref="cards">
      <v-row
        no-gutters
        class="animatedcards"
        :class="{'animatedcards--show': show_cards, 'animatedcards--hide': !show_cards}"
        justify="center"
      >
        <p class="work__title">{{ $t("howwework.title") }}</p>
      </v-row>
      <v-row no-gutters class="mt-12">
        <v-col
          v-for="(i, index) in howDoWeWork"
          :key="`how_${index}`"
          cols="12"
          sm="6"
          offset-sm="3"
          md="4"
          offset-md="0"
          class="animatedcards"
          :class="{'animatedcards--show': show_cards, 'animatedcards--hide': !show_cards}"
        >
          <v-card class="elevation-0 transparent">
            <v-card-text class="text-center">
              <v-icon x-large color="white">{{i.icon}}</v-icon>
            </v-card-text>
            <v-card-title primary-title class="layout justify-center text-center white--text card__title">{{i.title}}</v-card-title>
            <v-card-text class="text-justify white--text card__text mt-4" v-html="i.text"></v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      show_cards: false,
    };
  },
  computed: {
    howDoWeWork() {
      return [
        {
          icon: 'mdi-flash',
          title: this.$t('howwework.speed.title'),
          text: this.$t('howwework.speed.content'),
        },
        {
          icon: 'mdi-account-group',
          title: this.$t('howwework.teamwork.title'),
          text: this.$t('howwework.teamwork.content'),
        },
        {
          icon: 'mdi-wrench',
          title: this.$t('howwework.collaboration.title'),
          text: this.$t('howwework.collaboration.content'),
        },
      ];
    },
  },
  mounted() {
    window.addEventListener('scroll', this.listener);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.listener);
  },
  methods: {
    listener() {
      const vm = this;
      this.$nextTick().then(() => {
        const cardsdistance = vm.$refs.cards.getBoundingClientRect().top - window.innerHeight;
        const cardsVisible = cardsdistance < -180;
        if (cardsVisible !== vm.show_cards) {
          vm.show_cards = cardsVisible;
        }
      });
    },
  },
};
</script>

<style  scoped lang="scss">
.work__container {
  padding-top: 72px;
  background-color: $djablue;
  padding-bottom: 72px;
}
.work__title {
  font-size: 2.5rem;
  font-weight: 900;
  color: white;
}
.card__title {
  font-size: 1.5rem !important;
  line-height: 2rem;
  font-weight: 700;
}
.card__text {
  font-weight: 600;
}
</style>
