<template>
  <v-col cols="12">
    <v-hover :disabled="$vuetify.breakpoint.mobile" v-slot="{ hover }">
      <v-card
        class="proj-card"
        :class="$vuetify.breakpoint.mobile ? 'mx-8' : ''"
        elevation="0"
        hover
        rounded="0"
        color="primary"
        @click="clickCard(item)"
      >
        <v-img
          :class="{ 'on-hover': hover }"
          :src="item.imgSrc"
          class="white--text align-end animated-img"
          :position="item.position ? item.position : '30% 0%'"
          :height="height"
          :gradient="$vuetify.breakpoint.mobile && !item.hover_mobile ? 'to top, rgba(10,10,10,1) 0%, 28%, rgba(10,10,10,0) 40%' : ''"
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
          <v-card-title v-if="$vuetify.breakpoint.mobile && !item.hover_mobile">
            <span
              class="d-inline-flex justify-space-between text-caption w-100"
            >
              <p
                class="font-weight-medium"
              >
                {{ item.client }}
              </p>
              <p
                v-if="item.country"
                class="font-weight-medium"
              >
                {{ item.country }}
              </p>
              <p
                class="font-weight-medium"
              >
                {{ item.year }}
              </p>
            </span>
            <p
              class="font-weight-medium text-center text-body-1"
            >
              {{ item.title }}
            </p>
          </v-card-title>
        </v-img>
        <v-fade-transition>
          <v-overlay
            opacity="0.85"
            v-if="(!$vuetify.breakpoint.mobile && hover) || ($vuetify.breakpoint.mobile && item.hover_mobile)"
            absolute
            color="rgba(0,0,0,0.8)"
          >
            <v-row class="mx-sm-1 mx-2">
              <div>
                <p
                  v-if="item.country"
                  class="font-weight-medium mx-10 white--text text-shadow"
                >
                  {{ item.country }}
                </p>
                <p
                  class="font-weight-medium mx-10 text-h6 text-center white--text text-shadow"
                >
                  {{ item.title }}
                </p>
                <span
                  class="d-inline-flex justify-space-between px-12 px-md-2 px-lg-12 mt-5 mt-lg-7 w-100 text-shadow"
                >
                  <p
                    class="font-weight-medium white--text"
                  >
                    {{ item.client }}
                  </p>
                  <p
                    class="font-weight-light white--text"
                  >
                    {{ item.year }}
                  </p>
                </span>
                <p
                  class="font-weight-light mt-3 mx-3 mx-lg-10 my-auto text-center white--text text-shadow"
                >
                  {{ item.text }}
                </p>
              </div>
            </v-row>
          </v-overlay>
        </v-fade-transition>
      </v-card>
    </v-hover>
  </v-col>
</template>

<script>
export default {
  name: 'OurProjectsRow',
  props: {
    item: {
      type: Object,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
  },
  methods: {
    /* eslint no-param-reassign: ["error", { "props": false }] */
    clickCard(item) {
      item.hover_mobile = !item.hover_mobile;
    },
  },
};
</script>

<style scoped lang="scss">
.v-card__title {
  word-break: normal !important;
}
.text-shadow {
  text-shadow: 0 0 5px black;
}
.proj-card {
  transition: all 0.6s ease;
  cursor: default;
  overflow: hidden;
}
.animated-img {
  transition: transform .5s ease;
  transform: scale(1.01);
}
.on-hover {
  filter: grayscale(100%);
  transform: scale(1.1);
}
</style>
