<template>
  <v-row no-gutters class="bg-secondary py-12">
    <v-container>
      <div class="" ref="us_cards">
        <v-row
          no-gutters
          justify="center"
          class="animatedcards"
          :class="{
            'animatedcards--show': show_cards,
            'animatedcards--hide': !show_cards,
          }"
        >
          <h1 class="white--text">{{ $t("our_team.title") }}</h1>
        </v-row>
        <v-row
          justify="center"
          class="mt-12 members__wrapper"
          :class="hovered && 'members__wrapper--hovered'"
        >
          <v-col
            v-for="(member, index) in members"
            :key="`member_${index}`"
            cols="10"
            sm="5"
            md="4"
            lg="3"
            xl="2"
            class="flip-card"
            :class="{ mobile: $vuetify.breakpoint.mobile, 'flip-card--flipped': clicked === index }"
          >
            <div
              class="member__container flip-card-inner"
              @mouseenter="hovered = true"
              @mouseleave="hovered = false"
            >
              <v-img
                :src="false && require('@/assets/img/team/placeholder.jpg') || require('@/assets/img/team/' + member.pic)"
                class="member__pic shadow flip-card--front"
                alt="member.name"
                :lazy-src="require('@/assets/img/team/' + member.pic)"
                aspect-ratio="1"
                @click="clicked = index"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular indeterminate color="grey lighten-5" />
                  </v-row>
                </template>
                <div class="fill-height">
                  <div class="member__text">
                    <div class="member__text__name">{{ member.name }}</div>
                    <div class="member__text__role">{{ member.role }}</div>
                    <div class="member__text__phrase">{{ member.phrase }}</div>
                  </div>
                  <!-- <div
                  class="member__text"
                  v-html="getMemberText(member)"
                  :style="{fontSize: ($vuetify.breakpoint.name == 'xs' ? '0.67em' :
                                      $vuetify.breakpoint.name == 'sm' ? '0.87em' :
                                      $vuetify.breakpoint.name == 'md' ? '0.8em' :
                                      $vuetify.breakpoint.name == 'lg' ? '0.9em' :
                                      $vuetify.breakpoint.name == 'xl' ? '1.8em' :
                                      '0.8em')}"
                ></div> -->
                </div>
              </v-img>
              <div class="flip-card--back secondary" @click="clicked = -1">
                <member-business-card v-bind="{...member}" />
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </v-row>
</template>

<script>
import members from '@/assets/data/team';
import MemberBusinessCard from '@/components/MemberBusinessCard';

export default {
  components: { MemberBusinessCard },
  data() {
    return {
      members,
      show_cards: false,
      hovered: false,
      clicked: -1,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.listener);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.listener);
  },
  methods: {
    listener() {
      const vm = this;
      this.$nextTick().then(() => {
        const cardsdistance = vm.$refs.us_cards.getBoundingClientRect().top - window.innerHeight;
        const cardsVisible = cardsdistance < 0;
        if (cardsVisible !== vm.show_cards) {
          vm.show_cards = cardsVisible;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.members__wrapper {
  .member__container ::v-deep .v-image {
    transition: filter 0.15s linear;
  }
  &--hovered {
    :not(.flip-card--flipped) .member__container:not(:hover) {
      ::v-deep .v-image {
        filter: grayscale(0.4) blur(2px);
      }
    }
  }
  .member__container:hover {
    .member__text__phrase {
      max-height: 500px;
      opacity: 1;
    }
  }
}

.member__container {
  position: relative;
  text-align: center;
  color: white;

  ::v-deep .v-image {
    border-radius: 5%;
  }

  .member__text {
    width: 100%;
    background: linear-gradient(
      0deg,
      rgba(black, 0.8) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    bottom: 0;
    color: white;
    $text-shadow: null;

    @for $i from 1 through 1 {
      $text-shadow: $text-shadow, 0px 0px 3px rgba(black, 80%);
    }
    text-shadow: $text-shadow;

    text-align: right;
    position: absolute;
    padding: 4.5rem 1rem 1rem 1rem;

    &__phrase {
      max-height: 0;
      opacity: 0;
      padding-top: 0.5rem;
    }
  }
  $phrase-delay: 0.3s;

  &:hover {
    .member__text__phrase {
      transition: max-height $phrase-delay ease-in-out,
        opacity 0.3s ease-in $phrase-delay;
    }
  }

  &:not(:hover) {
    .member__text__phrase {
      //transition: all $phrase-delay ease-out;
    }
  }

}


.flip-card {
  background-color: transparent;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  cursor: pointer;
  text-align: center;
  transition: transform 0.4s cubic-bezier(0.9,0,0,0.9),
    filter 0.2s linear;
  transform-style: preserve-3d;

}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card--flipped .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card {
  &--front {
  }
  &--back {
    position: absolute;
    top: 0;
    border-radius: 5%;
    transform: rotateY(180deg);
    box-shadow: 3px 8px 20px rgba(black, 80%);
  }
  &--front, &--back {
    height: 100%;
    width: 100%;
    backface-visibility: hidden;
  }
}
</style>
