<template>
  <div class="home">
    <app-bar :site_sections="site_sections" :contact="contact" :portfolio="portfolio"/>
    <portrait id="portrait"/>
    <how-we-work id="our-work"/>
    <technologies />
    <our-projects id="projects"/>
    <clients id="clients"/>
    <about-us id="team" />
  </div>
</template>

<script>
// @ is an alias to /src

import AppBar from '../components/AppBar';
import Portrait from '../components/Portrait';
import HowWeWork from '../components/HowWeWork';
import OurProjects from '../components/OurProjects';
import Technologies from '../components/Technologies';
import Clients from '../components/Clients';
import AboutUs from '../components/AboutUs';

export default {
  name: 'Home',
  components: {
    AppBar,
    Portrait,
    HowWeWork,
    OurProjects,
    Technologies,
    Clients,
    AboutUs,
  },
  computed: {
    site_sections() {
      return [ // To be used in app bar
        {
          title: this.$t('app_bar.howwework'),
          id: 'our-work',
        },
        {
          title: this.$t('app_bar.our_work'),
          id: 'projects',
        },
        {
          title: this.$t('app_bar.client'),
          id: 'clients',
        },
        {
          title: this.$t('app_bar.team'),
          id: 'team',
        },
      ];
    },
    contact() {
      return {
        title: this.$t('app_bar.contact_us'),
        id: 'contact',
      };
    },
    portfolio() {
      return [
        {
          title: this.$t('app_bar.portfolio'),
          route: { name: 'Projects' },
        },
        // {
        //   title: 'Portafolio (2)',
        //   route: { name: 'Projects2' },
        // },
      ];
    },
  },
};
</script>
