<template>
    <div class="w-100 h-100" style="resize: both; background-color: transparent;">
        <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="xMinYMin meet">
            <rect x="0" y="0" rx="5" ry="5" width="100" height="100" />
            <image width="24" x="65" y="10" preserveAspectRatio="" :xlink:href="require('@/assets/img/1x_PNG/logo_principal_negativo.png')" />
            <g transform="translate(10,50)">
                <text x="0" y="0" class="tag">&lt; info - contacto &gt;</text>
                <text x="10" y="6" class="name">{{ name }}</text>
                <text x="10" y="12" class="role">{{ role }}</text>
                <text x="10" y="24" class="contact">{{ email }}</text>
                <text v-if="phone" x="10" y="30" class="contact">{{ phone }}</text>
                <text x="0" :y="phone ? 36 : 30" class="tag">&lt; / info - contacto &gt;</text>
            </g>
        </svg>
    </div>
</template>
<script>
export default {
  name: 'MemberBusinessCard',
  props: {
    name: {
      type: String,
      required: true,
    },
    role: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: false,
    },
    phone: {
      type: String,
      required: false,
    },
  },
};
</script>
<style lang="scss" scoped>
svg {
    rect {
        fill: $djablue;
    }

    text {
        font-size: 25%;
        font-family: Montserrat;
        font-weight: 500;
        letter-spacing: 0px;
        word-spacing: 0px;
        fill: $verdjavu;

        &:is(.name, .role) {
            font-weight: 600;
        }

        &.tag {
            fill: white;
            letter-spacing: 0.3px;
        }
    }
}
</style>
