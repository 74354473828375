<template>
  <v-row no-gutters class="my-12 py-12">
    <v-container ref="tech_cards" class="animatedcards"
        :class="{'animatedcards--show': show_cards, 'animatedcards--hide': !show_cards}">
      <v-row
        no-gutters
        justify="center"
      >
        <p class="display-1 font-weight-bold secondary--text">{{ $t('tech_puzzle.title') }}</p>
      </v-row>
      <v-row
        no-gutters
        justify="center"
        class="mt-2"
      >
        <p class="text-center secondary--text font-weight-medium">
          {{ $t('tech_puzzle.content') }}
        </p>
      </v-row>
      <v-row
        no-gutters
        justify="center"
      >
        <div class="slick-container">
          <slick ref="slick" :options="slickOptions">
            <div
              v-for="(item, index) in technologies"
              :key="`tech_${index}`"
              class="d-inline-flex justify-center align-center"
              style="height: 120px !important; padding: 20px !important;"
            >
              <v-img
                v-if="item.src"
                :src="require(`@/assets/img/technologies/${item.src}`)"
                :alt="item.alt"
                class="tech__icon"
                contain
              />
            </div>
          </slick>
        </div>
      </v-row>
    </v-container>
  </v-row>
</template>

<script>
import Slick from 'vue-slick';
import '../../node_modules/slick-carousel/slick/slick.css';
import '../../node_modules/slick-carousel/slick/slick-theme.css';

export default {
  components: {
    Slick,
  },
  data() {
    return {
      technologies: [
        {
          alt: 'django',
          src: 'django.png',
        },
        {
          alt: 'drf',
          src: 'drf.png',
        },
        {
          alt: 'vue js',
          src: 'vue.png',
        },
        {
          alt: 'aws',
          src: 'aws.png',
        },
        {
          alt: 'postgressql',
          src: 'postgresql.png',
        },
        {
          alt: 'github',
          src: 'github.png',
        },
        {
          alt: 'docker',
          src: 'docker.png',
        },
        {
          alt: 'docker-compose',
          src: 'dockercompose.png',
        },
        {
          alt: 'gcloud',
          src: 'gcloud.png',
        },
        {
          alt: 'nginx',
          src: 'nginx.png',
        },
        {
          alt: 'ms azure',
          src: 'azure.png',
        },
        {
          alt: 'firebase',
          src: 'firebase.png',
        },
        {
          alt: 'celery',
          src: 'celery.png',
        },
        {
          alt: 'vuetify',
          src: 'vuetify.png',
        },
        {
          alt: 'yarn',
          src: 'yarn.png',
        },
        {
          alt: 'android',
          src: 'android.svg',
        },
        {
          alt: 'kotlin',
          src: 'kotlin.png',
        },
        {
          alt: 'gitlab',
          src: 'gitlab.png',
        },
        {
          alt: 'bitbucket',
          src: 'bitbucket.png',
        },
        {
          alt: 'trello',
          src: 'trello.png',
        },
        {
          alt: 'slack',
          src: 'slack.png',
        },
        {
          alt: 'jira',
          src: 'jira.png',
        },
        {
          alt: 'opencv',
          src: 'opencv.png',
        },
        {
          alt: 'apache',
          src: 'apache.png',
        },
        {
          alt: 'redis',
          src: 'redis.png',
        },
        {
          alt: 'sphinx',
          src: 'sphinx.png',
        },
        {
          alt: 'arduino',
          src: 'arduino.png',
        },
        {
          alt: 'react',
          src: 'react.png',
        },
        {
          alt: 'twilio',
          src: 'twilio.png',
        },
        {
          alt: 'selenium',
          src: 'selenium.png',
        },
        {
          alt: 'npm',
          src: 'npm.svg',
        },
      ],
      slickOptions: {
        dots: true,
        arrows: false,
        slidesToShow: 5,
        slidesToScroll: 3,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
            },
          },
        ],
      },
      technology_index: 0,
      show_cards: false,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.listener);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.listener);
  },
  beforeUpdate() {
    if (this.$refs.slick) {
      this.$refs.slick.destroy();
    }
  },
  updated() {
    this.$nextTick(function () {
      if (this.$refs.slick) {
        this.$refs.slick.create(this.slickOptions);
      }
    });
  },
  methods: {
    listener() {
      const vm = this;
      this.$nextTick().then(() => {
        const cardsdistance = vm.$refs.tech_cards.getBoundingClientRect().top - window.innerHeight;
        const cardsVisible = cardsdistance < 0;
        if (cardsVisible !== vm.show_cards) {
          vm.show_cards = cardsVisible;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.technology-icon {
  margin-left: auto;
  margin-right: auto;
  filter: grayscale(100%);
  opacity: 0.6;
  transition: filter 0.3s ease, transform 0.3s ease;
}
.technology-icon.icon-hover {
  filter: grayscale(40%);
  transform: translateY(-0.7em);  /* match this with .technology-name font-size*/
}
.technology-icon.icon-hover.small-display
{
  transform: translateY(-2.5vw); /* match this with .technology-name.small-display font-size*/
}
.technology-name {
  font-size: 0.7em;
  opacity: 0;
  transition: opacity .3s ease;
}
.technology-name.name-hover{
  opacity: 1;
}
.technology-name.small-display
{
  font-size: 2.5vw;
}
.tech__subtitle {
  // font-weight: 600;
  // color: $djablue;
  // font-size: 1rem;
  // text-align: center;
}
.slick-container {
  margin-top: 100px;
  max-width: 1022px;
  width: 100%;
  max-height: 200px;
}
.tech__icon {
  width: 100px !important;
  height: 100px !important;
  margin: 0 10px;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  transition: all .3s ease;
  filter: grayscale(80%);
  opacity: 0.8;

  &:hover {
    width: 120px !important;
    height: 120px !important;
    margin: 0;
    filter: grayscale(0%);
    opacity: 1;
  }
}
</style>
