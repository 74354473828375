<template>
  <v-row no-gutters
    class="mt-12 py-12 primary">
    <v-container
        ref="proj_cards">
      <v-row
        no-gutters
        justify="center"
        class="pt-16 animatedcards"
        :class="{'animatedcards--show': show_cards, 'animatedcards--hide': !show_cards}"
      >
        <p class="display-1 white--text font-weight-bold">{{ $t('our_work.title') }}</p>
      </v-row>
      <v-row
        no-gutters
        justify="center"
        class="mt-2 mb-16 animatedcards"
        :class="{'animatedcards--show': show_cards, 'animatedcards--hide': !show_cards}"
      >
        <p class="white--text font-weight-medium">
          {{ $t('our_work.content') }}
        </p>
      </v-row>
      <v-row justify="center" class="mt-16 animatedcards"
        :class="{'animatedcards--show': show_cards, 'animatedcards--hide': !show_cards}">
        <v-col cols="12" md="6">
          <v-row >
            <template v-for="(item, i) in firstCol">
              <our-projects-row :item="item" :height="computedHeight(item.height)" :key="`L_project_${i}`"/>
            </template>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <v-row >
            <template v-for="(item, i) in secondCol">
              <our-projects-row :item="item" :height="computedHeight(item.height)" :key="`R_project_${i}`"/>
            </template>
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-btn color="white" rounded outlined @click="$router.push({name: 'Projects' })" class="my-16">{{ $t('our_work.see_all') }}</v-btn>
      </v-row>
    </v-container>
  </v-row>
</template>
<script>
import OurProjectsRow from './OurProjectsRow';

export default {
  name: 'OurProjects',
  components: { OurProjectsRow },
  data() {
    return {
      show_cards: false,
      items: [
        {
          // eslint-disable-next-line
          imgSrc: require('@/assets/img/projects/btg.png'),
          title: this.$t('projects.btg.title'),
          text: this.$t('projects.btg.text'),
          client: 'BTG Pactual',
          year: `2022 - ${this.$t('our_work.now')}`,
          type: 'Desarrollo',
          URL: 'http://ml.cl/',
          height: 600,
          hover_mobile: false,
        },
        {
          // eslint-disable-next-line
          imgSrc: require('@/assets/img/projects/teselagen.png'),
          title: this.$t('projects.teselagen.title'),
          text: this.$t('projects.teselagen.text'),
          client: 'TeselaGen',
          country: '🇺🇸 EEUU',
          year: '2023',
          type: 'Desarrollo',
          URL: 'https://teselagen.com/design-module/',
          height: 300,
          hover_mobile: false,
        },
        {
          // eslint-disable-next-line
          imgSrc: require('@/assets/img/projects/venew.png'),
          title: 'Venew: Portal para arriendo de espacios por hora',
          text: 'Plataforma web para la oferta de espacios destinados a eventos, que pueden ser arrendados por hora.',
          client: 'Venew',
          year: '2020 - 2021',
          type: 'Desarrollo',
          URL: 'https://venew.co/',
          height: 300,
          hover_mobile: false,
        },
        {
          // eslint-disable-next-line
          imgSrc: require('@/assets/img/projects/salah.png'),
          title: this.$t('projects.salah.title'),
          text: this.$t('projects.salah.text'),
          client: 'Sebastián Salah',
          year: `2021 - ${this.$t('our_work.now')}`,
          type: 'Desarrollo',
          height: 300,
          hover_mobile: false,
        },
        {
          // eslint-disable-next-line
          imgSrc: require('@/assets/img/projects/consilium-eeuu.png'),
          title: 'Plataforma de Información escolar',
          country: '🇺🇸 EEUU',
          text: 'Desarrollo de una plataforma web informativa de las escuelas públicas de New Haven, Connecticut.',
          client: 'ConsiliumBots',
          year: '2020 - 2021',
          type: 'Desarrollo',
          URL: 'https://explore.newhavenmagnetschools.com/',
          height: 600,
          hover_mobile: false,
        },
        {
          // eslint-disable-next-line
          imgSrc: require('@/assets/img/projects/consilium-ecuador.png'),
          title: 'Plataforma de Información y postulación escolar',
          country: '🇪🇨 Ecuador',
          text: 'Desarrollo de una plataforma web informativa y para postulación a escuelas de la Provincia de Manabí, Ecuador.',
          client: 'ConsiliumBots',
          year: '2020 - 2021',
          type: 'Desarrollo',
          URL: 'https://inscripcionmanta.educacion.gob.ec/',
          height: 300,
          hover_mobile: false,
        },
        {
          imgSrc: 'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/c2a24572804813.5bf41d8429641.jpg',
          title: 'Plataforma de Información y postulación escolar',
          country: '🇧🇷 Brasil',
          text: 'Desarrollo de una plataforma web informativa y para postulación a las escuelas de Caruaru, Pernambuco.',
          client: 'ConsiliumBots',
          type: 'Desarrollo',
          hover_mobile: false,
          height: 450,
          year: 2020,
        },
        {
          imgSrc: 'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/c2a24572804813.5bf41d8429641.jpg',
          title: 'Prototipo Plataforma de Postulacion',
          country: '🇵🇪 Perú',
          text: 'Desarrollo del prototipo de una plataforma web informativa y para postulación a las escuelas de Tacna, Perú.',
          client: 'ConsiliumBots',
          type: 'Desarrollo',
          hover_mobile: false,
          height: 300,
          position: '0% 50%',
          year: 2019,
        },
      ].map(project => ({ ...project, country: project.country || '🇨🇱 Chile' })),
    };
  },
  computed: {
    firstCol() {
      let items = [];
      items = items.concat(this.items);
      return items.slice(0, 3);
    },
    secondCol() {
      let items = [];
      items = items.concat(this.items);
      return items.slice(3, 6);
    },
  },
  mounted() {
    window.addEventListener('scroll', this.listener);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.listener);
  },
  methods: {
    listener() {
      const vm = this;
      this.$nextTick().then(() => {
        const cardsdistance = vm.$refs.proj_cards.getBoundingClientRect().top - window.innerHeight;
        const cardsVisible = cardsdistance < -100;
        if (cardsVisible !== vm.show_cards) {
          vm.show_cards = cardsVisible;
        }
      });
    },
    computedHeight(i) {
      if (this.$vuetify.breakpoint.mobile) return 400;
      return i;
    },
    /* eslint no-param-reassign: ["error", { "props": false }] */
    clickCard(item) {
      item.hover_mobile = !item.hover_mobile;
    },
  },
};
</script>
<style scoped lang="scss">
.overlay {
  position: absolute;
  transition: opacity 0.6s ease-in-out;
  z-index: 10;
  background: black;
  height: 100%;
  width: 33.3%;
  &--show {
    opacity: 0;
  }
  &--hide {
    opacity: 0.5;
  }
}
.v-card__title {
  word-break: normal !important;
}
.proj-card {
  transition: all 0.6s ease-in-out;
  cursor: default;
}
.on-hover {
  filter: grayscale(100%);
  transform: scale(1.3);
}
</style>
