<template>
  <section
    id="top"
    class="background"
    :style="`height: 100vh - 84px; background-image: url('${require('@/assets/img/back.jpg')}'); min-height: 500px;`"
  >
    <div class="d-inline-flex overlay align-center w-100 h-100">
      <div class="content-container">
        <div class="d-inline-flex align-center">
          <p class="portrait__text pr-2">{{ $t("landing.welcome_to") }}</p>
          <div class="d-inline-flex align-center">
            <img
            style="height: 30px;"
            :src="require('@/assets/img/1x_PNG/logo_principal_negativo.png')"
            @click="window.scrollTo({top: 0, left: 0, behavior: 'smooth'})"
            />
          </div>
        </div>
        <div class="portrait__title-container mt-6">
          <p class="portrait__title">
            {{ $t("landing.title") }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      is_mounted: true,
    };
  },
  mounted() {
    const vm = this;
    window.setTimeout(() => { vm.is_mounted = true; }, 300);
  },
};
</script>

<style scoped lang="scss">
.background {
  background-position: center center;
  background-repeat: repeat;
  background-size: cover;
}
.main-logo {
  opacity: 0;
  transform: scale(0.7);
  /* csslint ignore:start */
  transition: opacity 1s cubic-bezier(0.175, 0.885, 0.32, 1.275), transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  /* csslint ignore:end */
}
.main-logo-show {
  opacity: 1;
  transform: scale(1);
}
.overlay {
  background: rgba(0,0,0,0.8);
  z-index: 1;
  height: 100vh;
  width: 100%;
  min-height: 500px;
}
.content-container {
  padding-top: 110px;
  width: 100%;
  padding-inline: 15%;
}
.portrait__text {
  color:  white;
  font-size: 20px;
  font-weight: 400;
}
.portrait__title-container {
  max-width: 700px;
}
.portrait__title {
  text-align: left !important;
  font-size: 2.8rem;
  @media #{map-get($display-breakpoints, 'sm-only')} {
    font-size: 2rem;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    font-size: 2rem;
  }
  font-weight: 700;
  color:  white;
  line-height: 3rem;
}
</style>
